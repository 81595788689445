import { NotificationType } from '@local/web-design-system';
import { isEmpty } from 'lodash-es';
import { Dispatch } from 'redux';
import { from as observableFrom, concatMap, mergeMap, toArray } from 'rxjs';
import { DEFAULT_FILTERS, CLIENT_SIDE_PAGINATION_LIMIT } from 'state-domains/constants';
import { FilterObject } from 'state-domains/domain/drillhole';
import { createFilterFromSearchTerm } from 'state-domains/domain/utils';
import { GetState } from 'state-domains/types';
import { getBoundingBoxGeoJSONQuadrants } from 'src/components/Drillhole/DrillholeMap';
import { MapLayer } from 'src/components/Drillhole/DrillholeMap/DrillholeMap.types';
import { UserPortalState } from 'src/state';

import {
    LIST_PROJECTS,
    SELECT_PROJECT,
    SET_SEARCH_TERM,
    PIN_PROJECT,
    ADD_SNACKBAR_NOTIFICATION,
    SELECTED_PROJECT_PERMISSIONS,
    MAP_VIEW_STATE,
    LOAD_OVERVIEW_COLLARS_WITH_COUNT,
    CLEAR_OVERVIEW_COLLARS,
    LOAD_PROJECT,
    CLEAR_PROJECT_LOAD_STATE,
    LOAD_PROJECT_FILE_GROUP,
} from '../../../types/actionTypes';
import { typeComplete, typeFail, typePending } from '../../../utils';
import {
    selectors as userStateSelectors,
    selectors as userSelectors,
} from '../../user/state/selectors';
import { projectShim } from '../shim';
import { ActivityTableList } from '../types';
import { getFiltersForOverviewCollars, getOverviewMapBounds } from '../utils';

import { selectors as projectSelectors } from './selectors';

const loadProjectList = () => (dispatch: Dispatch, getState: any) => {
    dispatch({ type: typePending(LIST_PROJECTS) });
    const { currentUserId } = userStateSelectors;
    const userId = currentUserId(getState());
    observableFrom([projectShim.loadProjectList, projectShim.loadProjectFileGroups])
        .pipe(concatMap((action) => action()))
        .pipe(toArray())
        .subscribe({
            next: (responses: any[]) => {
                const projectsList = responses[0];
                const projectFileGroups = responses[1];

                if (projectsList) {
                    dispatch({
                        type: typeComplete(LIST_PROJECTS),
                        payload: { projectsList, userId, projectFileGroups },
                    });
                } else {
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: {
                            type: NotificationType.ERROR,
                            message: 'Projects list could not be loaded',
                        },
                    });
                }
            },
            error: (error: Error) => {
                dispatch({ type: typeFail(LIST_PROJECTS), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
};

const loadProject = (projectId: string) => (dispatch: Dispatch, _getState: GetState) => {
    dispatch({ type: typePending(LOAD_PROJECT) });
    observableFrom([
        {
            action: projectShim.loadProjectList,
            params: [],
        },
        {
            action: projectShim.loadProjectFileGroups,
            params: [projectId],
        },
    ])
        .pipe(concatMap(({ action, params }) => action(...params)))
        .pipe(toArray())
        .subscribe({
            next: (response: any[]) => {
                const project = response[0];
                const projectFileGroup = response[1];
                dispatch({
                    type: typeComplete(LOAD_PROJECT),
                    payload: { project, projectFileGroup },
                });
            },
            error: (error: Error) => {
                dispatch({ type: typeFail(LOAD_PROJECT), payload: { error } });
                dispatch({
                    type: ADD_SNACKBAR_NOTIFICATION,
                    payload: { type: NotificationType.ERROR, message: error },
                });
            },
        });
};

const loadProjectFileGroup = (projectId: string) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(LOAD_PROJECT) });
    return projectShim.loadProjectFileGroups(projectId).subscribe({
        next: (fileGroup) => {
            dispatch({ type: typeComplete(LOAD_PROJECT_FILE_GROUP), payload: fileGroup });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(LOAD_PROJECT), payload: { error } });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const setPinProject = (projectId: string, pin: boolean) => (dispatch: Dispatch) => {
    dispatch({ type: typePending(PIN_PROJECT) });
    return projectShim.setPinProject(projectId, pin).subscribe({
        next: () => {
            dispatch({
                type: typeComplete(PIN_PROJECT),
                payload: { projectId, pin },
            });
        },
        error: (error: any) => {
            dispatch({ type: typeFail(PIN_PROJECT), payload: { error } });
            dispatch({
                type: ADD_SNACKBAR_NOTIFICATION,
                payload: { type: NotificationType.ERROR, message: error },
            });
        },
    });
};

const getFilters = (projId: string, userId: string, subscriptionId: string) => {
    const filters = getFiltersForOverviewCollars(projId, userId, subscriptionId);
    return isEmpty(filters) ? DEFAULT_FILTERS : filters;
};

const { currentUserId } = userSelectors;
const { getSearchTerm } = projectSelectors;
export const loadOverviewCollarsWithCount =
    (projectId: string) => (dispatch: any, getState: any) => {
        dispatch({ type: typePending(LOAD_OVERVIEW_COLLARS_WITH_COUNT) });
        const allState = getState() as UserPortalState;
        const userId = currentUserId(allState);
        const searchTerm = getSearchTerm(allState);
        const currentSubscriptionId = allState.user.selected.id;
        const dhFilters = getFilters(projectId, userId, currentSubscriptionId);
        const filters = (dhFilters.filters as FilterObject[]) ?? [];
        if (searchTerm) filters.push(createFilterFromSearchTerm(searchTerm) as any);
        const boundingBox = getOverviewMapBounds(projectId, userId, currentSubscriptionId);
        const coordinates = getBoundingBoxGeoJSONQuadrants(boundingBox);

        const subscription = observableFrom([
            {
                action: projectShim.loadProjectOverviewCollarsCount,
                coordinates,
            },
            {
                action: projectShim.loadProjectOverviewCollars,
                coordinates,
            },
        ]);
        return subscription
            .pipe(
                mergeMap(
                    (subShim: { action: Function; coordinates: number[][] }) =>
                        subShim.action(
                            projectId,
                            dhFilters.activities as string[],
                            dhFilters.state as string[],
                            filters,
                            subShim.coordinates,
                        ),
                    5,
                ),
            )
            .pipe(toArray())
            .subscribe({
                next: (response: any) => {
                    dispatch({
                        type: typeComplete(LOAD_OVERVIEW_COLLARS_WITH_COUNT),
                        payload: response,
                    });
                },
                error: (error: any) => {
                    dispatch({
                        type: typeFail(LOAD_OVERVIEW_COLLARS_WITH_COUNT),
                        payload: { error },
                    });
                    dispatch({
                        type: ADD_SNACKBAR_NOTIFICATION,
                        payload: { type: NotificationType.ERROR, message: error },
                    });
                },
            });
    };

const setSearchTerm =
    (searchTerm: string, offset = 0, limit: number = CLIENT_SIDE_PAGINATION_LIMIT) =>
    (dispatch: Dispatch) =>
        dispatch({
            type: SET_SEARCH_TERM,
            payload: { searchTerm, offset, limit },
        });

const selectProject = (projectUid: string) => (dispatch: Dispatch) =>
    dispatch({
        type: SELECT_PROJECT,
        payload: { projectUid },
    });

const updateSelectedProjectPermissions =
    (projectPermissions: any, tablesInActivities: ActivityTableList) => (dispatch: Dispatch) =>
        dispatch({
            type: SELECTED_PROJECT_PERMISSIONS,
            payload: { projectPermissions, tablesInActivities },
        });

const updateMapLayer = (mapType: MapLayer) => (dispatch: Dispatch) => {
    dispatch({
        type: MAP_VIEW_STATE,
        payload: { mapType },
    });
};

const clearOverviewCollars = () => (dispatch: Dispatch) => {
    dispatch({
        type: CLEAR_OVERVIEW_COLLARS,
    });
};

const clearProjectLoadState = () => (dispatch: Dispatch) => {
    dispatch({
        type: typeComplete(CLEAR_PROJECT_LOAD_STATE),
    });
};

export const actions = {
    loadProject,
    loadProjectList,
    loadProjectFileGroup,
    setSearchTerm,
    selectProject,
    setPinProject,
    updateSelectedProjectPermissions,
    updateMapLayer,
    loadOverviewCollarsWithCount,
    clearOverviewCollars,
    clearProjectLoadState,
};
